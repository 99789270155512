import React, { useState, useEffect } from "react";
import { Container, Table, Button, Modal, Form, Alert } from "react-bootstrap";

const AlbumPackagesList = () => {
  const [albumPackages, setAlbumPackages] = useState([]);
  const [show, setShow] = useState(false);
  const [currentAlbumPackage, setCurrentAlbumPackage] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  return (
    <Container className="mt-5">
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}

      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Albüm Paket Listesi</h2>
      </div>
    </Container>
  );
};

export default AlbumPackagesList;
