import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ProcessStatusModal({ toggleModal, currentData }) {
  const [selectedStatus, setSelectedStatus] = useState(
    currentData.processStatusId
  );
  const [status, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchStatuses = async () => {
    setStatuses([
      {
        id: 1,
        name: "Görüşülmedi",
      },
      {
        id: 2,
        name: "Görüşüldü",
      },
      {
        id: 3,
        name: "Anlaşıldı",
      },
      {
        id: 4,
        name: "Anlaşılmadı",
      },
    ]);
    setLoading(false);
  };

  const handleClick = async () => {
    if (selectedStatus) {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          process.env.API_URL + `/v2/weddings/process/${selectedStatus}`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              contractID: parseInt(currentData.id),
            }),
          }
        );
        console.log(response);

        if (!response.ok) {
          throw new Error("Failed to update");
        }
        toggleModal();
      } catch (err) {
        setError(err.message);
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      // Handle the case where no personel is selected
      alert("Lütfen bir durum seçin.");
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchStatuses();
  }, []);

  return (
    <>
      <Modal
        show={true}
        onHide={toggleModal}
        size="l"
        fullscreen="lg-down"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Durum Ata / Değiştir</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading ? (
            <div className="d-flex justify-content-center align-middle">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Yükleniyor...</span>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-middle">
              <div className="form-floating col-lg-6 mb-3">
                <select
                  className="form-select"
                  id="processStatusID"
                  name="processStatusID"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="">Seçin</option>
                  {status.map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </select>
                <label
                  className="form-label ms-2 fw-bold"
                  style={{ fontSize: ".95em" }}
                  htmlFor="processStatusID"
                >
                  Durum Seçin:
                </label>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Kapat
          </Button>
          <Button variant="primary" onClick={handleClick}>
            Güncelle
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProcessStatusModal;
