import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function AddLocation({ reload, setReload }) {
  const [error, setError] = useState(null);

  const formikRef = useRef();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmitOutsideForm = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const initialValues = {
    name: "",
    price: "",
    color: "",
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Bu alanı doldurmak zorundasınız."),
    price: Yup.number()
      .typeError("Salon ücreti rakamlardan oluşmalıdır")
      .min(1, "Salon ücreti sıfırdan küçük olamaz.")
      .required("Bu alanı doldurmak zorundasınız."),
    color: Yup.string().required("Bu alanı doldurmak zorundasınız."),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const url = `${process.env.API_URL}${"/settings/locations"}`;

    const result = fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          name: values.name,
          price: values.price,
          color: values.color,
        },
      }),
    });

    const response = await result;

    if (response.ok) {
      toast.success("Salon başarıyla eklendi", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Salon eklenirken bir hata meydana geldi", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    if (!reload) setReload(true);
    setSubmitting(false);
    setShow(false);
  };

  return show ? (
    <Modal
      show={true}
      onHide={handleClose}
      size={"xl"}
      fullscreen="lg-down"
      scrollable
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="text-muted fs-6">Yeni Salon Ekle</div>
        </Modal.Title>
      </Modal.Header>

      {error ? (
        <Modal.Body className="d-flex justify-content-center py-5">
          <div className="row" role="status">
            <div className="alert alert-danger col-12 text-center" role="alert">
              Bir hata meydana geldi!
            </div>
          </div>
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              onSubmit={handleSubmit}
              innerRef={formikRef}
            >
              {({ values, handleChange }) => (
                <Form>
                  <div className="row mb-3">
                    <div className="fs-5 fw-bold text-center">
                      Salon Bilgileri
                    </div>
                  </div>
                  <div className="row px-5 px-md-0 mb-1 justify-content-center">
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Salon Adı"
                      />
                      <label className="form-label ms-3" htmlFor="name">
                        Salon Adı
                      </label>
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <div className="row px-5 px-md-0 mb-1 justify-content-center">
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        type="price"
                        className="form-control"
                        id="price"
                        name="price"
                        placeholder="Salon Ücreti"
                      />
                      <label className="form-label ms-3" htmlFor="price">
                        Salon Ücreti
                      </label>
                      <ErrorMessage
                        name="price"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>

                  <div className="row px-5 px-md-0 mb-1 justify-content-center">
                    <div className="form-floating col-lg-3 mb-3">
                      <Field
                        type="color"
                        className="form-control"
                        id="color"
                        name="color"
                        placeholder="Salon Rengi"
                      />
                      <label className="form-label ms-3" htmlFor="color">
                        Salon Rengi
                      </label>
                      <ErrorMessage
                        name="color"
                        component="div"
                        className="text-danger mt-1 ms-1"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Vazgeç
            </Button>
            <Button variant="success" onClick={handleSubmitOutsideForm}>
              Kaydet
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  ) : (
    <button
      className="btn btn-outline-success col-auto ms-auto me-md-2 fw-bold ul mb-2"
      onClick={handleShow}
    >
      <i className="bi bi-plus-circle-fill pe-1"></i> Yeni
    </button>
  );
}

export default AddLocation;
