import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { usePrintContext } from "../../printCanvases/contextWrapper/PrintContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PrintLiteBridalContractsAsAListModal({
  toggleModal,
  closeModal,
  data,
  currentPage,
}) {
  const [fetchStatus, setFetch] = useState(false);

  const {
    setSecondPrintData,
    setListCurrentPage,
    setPrintContext,
    switchToSecondPrintPage,
    setSecondShouldPrint,
  } = usePrintContext();

  const handleSecondPrintButtonClick = (data, context) => {
    if (data.length > 0) {
      setSecondPrintData(data);

      setPrintContext(context);

      setListCurrentPage(currentPage.current);

      setSecondShouldPrint(true);

      switchToSecondPrintPage();

      closeModal();
    } else {
      toast.error(
        "Sözleşme listesi boş veya bugün hazırlanan sözleşme bulunamadı!",
        {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = new Date();
        const formattedStartDate = today.toISOString().split("T")[0];

        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        const formattedEndDate = tomorrow.toISOString().split("T")[0];

        const response = await fetch(
          `${process.env.API_URL}/liteBridalContracts?` +
            new URLSearchParams({
              orderBy: "contractID.asc",
              page: 1,
              filters: JSON.stringify({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
              }),
            }),
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw response;
        }

        const actualData = await response.json();
        handleSecondPrintButtonClick(actualData.payload, "lite-bridal");
      } catch (err) {
        if (err.status === 401) {
          toast.error(
            "Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız.",
            {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            }
          );
        } else if (err.message === "Failed to fetch") {
          toast.error("Veritabanı iletişim kurulamadı.", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        } else {
          toast.error(
            "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin.",
            {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            }
          );
        }
      } finally {
        setFetch(false);
      }
    };

    if (fetchStatus) {
      fetchData();
    }
  }, [fetchStatus]);

  return (
    <>
      <Modal show={true} onHide={toggleModal} size={"xl"} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-muted fs-6">Liste Yazdır</div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex gap-4 py-5 my-md-5 mx-3 justify-content-center align-items-center">
          <div
            className="btn btn-lg btn-primary col-md-3"
            onClick={() => handleSecondPrintButtonClick(data, "lite-bridal")}
          >
            Sayfadaki Listeyi Yazdır
          </div>
          <div
            className="btn btn-lg btn-primary col-md-3"
            onClick={() => setFetch(true)}
          >
            Bugünün Listesini Yazdır
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={450}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        transition:Flip
      />
    </>
  );
}
