import React from "react";

const ProcessStatus = ({ processStatusID, onClick }) => {
  let buttonClass, buttonText;

  switch (processStatusID) {
    case 0:
      buttonClass = "btn-light";
      buttonText = "İşlem Yapılmadı";
      break;
    case 1:
      buttonClass = "btn-danger";
      buttonText = "Görüşülmedi";
      break;
    case 2:
      buttonClass = "btn-info";
      buttonText = "Görüşüldü";
      break;
    case 3:
      buttonClass = "btn-success";
      buttonText = "Anlaşıldı";
      break;
    case 4:
      buttonClass = "btn-warning";
      buttonText = "Anlaşılmadı";
      break;
    default:
      buttonClass = "btn-light";
      buttonText = "İşlem Yapılmadı";
  }

  return (
    <button type="button" class={`btn ${buttonClass}`} onClick={onClick}>
      {buttonText}
    </button>
  );
};

export default ProcessStatus;
