import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function PersonelModal({ toggleModal, currentData }) {
  const [selectedPersonel, setSelectedPersonel] = useState(
    currentData.personelId
  );
  const [personels, setPersonels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logData, setLogData] = useState();

  // Fetch personels
  const fetchPersonels = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        process.env.API_URL + "/settings/personels",
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Personel Listesi Çekilemedi");
      }

      const data = await response.json();
      setPersonels(data.data.personels);
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async () => {
    if (selectedPersonel) {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          process.env.API_URL +
            `/v2/contracts/photos/personels/${selectedPersonel}`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              contractID: parseInt(currentData.id),
            }),
          }
        );
        console.log(response);

        if (!response.ok) {
          throw new Error("Failed to update personel");
        }
        toggleModal();
      } catch (err) {
        setError(err.message);
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      // Handle the case where no personel is selected
      alert("Lütfen bir personel seçin.");
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchPersonels();
    console.log(currentData);
  }, []);

  return (
    <>
      <Modal
        show={true}
        onHide={toggleModal}
        size="l"
        fullscreen="lg-down"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Personel Ata / Değiştir</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading ? (
            <div className="d-flex justify-content-center align-middle">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-middle">
              <div className="form-floating col-lg-6 mb-3">
                <select
                  className="form-select"
                  id="personelSelect"
                  name="personelSelect"
                  value={selectedPersonel}
                  onChange={(e) => setSelectedPersonel(e.target.value)}
                >
                  <option value="">Seçin</option>
                  {personels.map((personel) => (
                    <option key={personel.id} value={personel.id}>
                      {personel.name}
                    </option>
                  ))}
                </select>
                <label
                  className="form-label ms-2 fw-bold"
                  style={{ fontSize: ".95em" }}
                  htmlFor="personelSelect"
                >
                  Personel Seçin:
                </label>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Kapat
          </Button>
          <Button variant="primary" onClick={handleClick}>
            Güncelle
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PersonelModal;
