import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePrintContext } from "../../printCanvases/contextWrapper/PrintContext";
import { Button } from "react-bootstrap";
import WEDDINGTIME from "../../../constants/weddingTime";

export default function NewWeddingContract({ setFormDirty }) {
  const { setPrintData, switchToPrintPage, setShouldPrint } = usePrintContext();

  const navigate = useNavigate();
  const formRef = useRef();

  const validate = () => {
    if (formRef.current.dirty) {
      setFormDirty(true);
    } else {
      setFormDirty(false);
    }
  };

  const handlePrintButtonClick = (data) => {
    setPrintData(data);
    setShouldPrint("wedding");
    switchToPrintPage();
  };

  const [fetchStatus, setFetch] = useState(true);

  const [stageOne, setStageOne] = useState(true);
  const [stageTwo, setStageTwo] = useState(false);

  const [locations, setLocations] = useState({});
  const [additionalPrice, setAdditionalPrice] = useState(0);
  const [paketTutari, setPaketTutari] = useState(0);
  const [toplamTutar, setToplamTutar] = useState();

  const validationSchemaStageOne = Yup.object().shape({
    damatAdi: Yup.string()
      .min(3, "Damat adı 3 karakterden az olamaz")
      .max(41, "Damat adı 40 karakterden fazla olamaz")
      .required("Damat adı gerekli"),
    damatTelefonu: Yup.string().required("Telefon numarası gerekli"),
    gelinAdi: Yup.string()
      .min(3, "Gelin adı 3 karakterden az olamaz")
      .max(41, "Gelin adı 40 karakterden fazla olamaz")
      .required("Gelin adı gerekli"),
    gelinTelefonu: Yup.string().required("Telefon numarası gerekli"),
    adres: Yup.string()
      .min(3, "Adres 3 karakterden az olamaz")
      .max(126, "Adres 125 karakterden fazla olamaz")
      .required("Adres gerekli"),
  });

  const validateDamatTelefonu = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length !== 11) {
      return "Telefon numarası eksik olmamalıdır";
    }

    return undefined;
  };

  const validateGelinTelefonu = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length !== 11) {
      return "Telefon numarası eksik olmamalıdır";
    }

    return undefined;
  };

  const ValidationSchemaStageTwo = Yup.object().shape({
    dugunTarihi: Yup.date()
      .required("Düğün tarihi gerekli")
      .typeError("Düğün tarihi geçersiz"),
    aciklama: Yup.string()
      .max(101, "Açıklama 100 karakterden fazla olamaz")
      .notRequired(),
    dugunSalonu: Yup.string().required("Duğün salonu gereklidir"),
    kisiSayisi: Yup.number()
      .typeError("Kişi sayısı rakamlardan oluşmalıdır")
      .min(1, "Kişi sayısı sıfırdan yüksek olmalıdır")
      .required("Kişi sayısı gereklidir"),
    idSecenek: Yup.boolean().notRequired(),
    id: Yup.string().notRequired(),
  });

  const [stageOneValues, setStageOneValues] = useState({
    damatAdi: "",
    damatTelefonu: "",
    gelinAdi: "",
    gelinTelefonu: "",
    adres: "",
  });

  const [stageTwoValues, setStageTwoValues] = useState({
    dugunTarihi: "",
    dugunZamani: "gunduz",
    aciklama: "",
    dugunSalonu: "",
    kisiSayisi: "",
    odemeEkstra: "",
    id: "",
    odemeAlinan: "",
    odemeTuru: "Nakit",
    odemeIndirim: "0",
  });

  useEffect(() => {
    if (fetchStatus) {
      fetch(process.env.API_URL + "/settings/locations", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          if (!actualData.payload || !actualData.payload.length) {
            toast.error(
              "Düğün salonu bulunamadı, devam etmek için seçeneklerden salon ekleyin.",
              {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
              }
            );
          } else {
            setLocations(actualData.payload);
            if (actualData.payload[0].price) {
              setToplamTutar(actualData.payload[0].price);
              setPaketTutari(actualData.payload[0].price);
            }
            if (actualData.payload[0].name) {
              setStageTwoValues({
                ...stageTwoValues,
                dugunSalonu: actualData.payload[0].name,
              });
            }
          }
        })
        .catch(() => {
          toast.error(
            "Düğün salonu bilgileri çekilirken bir hata meydana geldi, lütfen tekrar deneyin",
            {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            }
          );
        })
        .finally(() => {
          setFetch(false);
          setTimeout(() => {}, 100);
        });
    }
  }, [stageTwoValues, fetchStatus]);

  const updateTotalPrice = (values) => {
    let totalPrice = Number(toplamTutar);

    setToplamTutar(totalPrice);
    setStageTwoValues(values);
  };

  const toggleStageOne = (values) => {
    if (values) {
      updateTotalPrice(values);
    }
    setStageOne(true);
    setStageTwo(false);
  };

  const toggleStageTwo = (values) => {
    if (values) {
      const temizDamatTel = values.damatTelefonu.replace(/\D/g, "");
      const temizGelinTel = values.gelinTelefonu.replace(/\D/g, "");
      const cleanedValues = {
        ...values,
        damatTelefonu: temizDamatTel,
        gelinTelefonu: temizGelinTel,
      };
      values = cleanedValues;
      setStageOneValues(values);
    }
    setStageTwo(true);
    setStageOne(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    let wedTime = "T13:00";
    if (values.dugunZamani === "aksam") {
      wedTime = "T21:00";
    }
    const finalWeddingDateTime = values.dugunTarihi + wedTime;
    let toplamUcret = Number(toplamTutar);
    let dugunSalonuId = values.dugunSalonu
      ? locations[
          locations.findIndex((item) => item.name === values.dugunSalonu)
        ].id
      : null;

    if (!dugunSalonuId) {
      setSubmitting(false);
      return toast.error("Düğün salonu olmadan sözleşme oluşturamazsınız", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    if (Number(values.odemeIndirim) > 0) {
      toplamUcret -= Number(values.odemeIndirim);
    }

    let alinanUcret = Number(toplamUcret) - Number(values.odemeAlinan);

    const result = await fetch(process.env.API_URL + "/weddingContracts", {
      method: "POST",
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        odemeTuru: values.odemeTuru,
        data: {
          contractID: Number(values.id.substring(3) > 0)
            ? Number(values.id.substring(3))
            : undefined,
          aciklama: values.aciklama || null,
          sozlesmeTarihi: new Date(),
          damatAdi: stageOneValues.damatAdi,
          damatTelefonu: stageOneValues.damatTelefonu,
          gelinAdi: stageOneValues.gelinAdi,
          gelinTelefonu: stageOneValues.gelinTelefonu,
          adres: stageOneValues.adres,
          dugunTarihi: new Date(finalWeddingDateTime),
          kisiSayisi: Number(values.kisiSayisi),
          dugunSalonuId:
            locations[
              locations.findIndex((item) => item.name === values.dugunSalonu)
            ].id,
          odemeAlinan: Number(values.odemeAlinan),
          odemeEkstra: null,
          odemeToplam: Number(toplamUcret),
          odemeIndirim:
            Number(values.odemeIndirim) > 0
              ? Number(values.odemeIndirim)
              : null,
          odemeKalan: Number(alinanUcret),
          paketFiyati: Number(paketTutari),
        },
      }),
    });

    if (result.ok) {
      toast.success("Sözleşme başarıyla oluşturuldu!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      const contract = await result.json();

      setFormDirty(false);
      handlePrintButtonClick(contract);
      await new Promise((resolve) => setTimeout(resolve, 200));

      navigate("/wedding-contracts/success");
    } else if (result.status === 400) {
      toast.error("Bu sıra no ile zaten bir sözleşme var!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Sözleşme oluşturulurken bir hata meydana geldi!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    setSubmitting(false);
  };

  return (
    <Fragment>
      <div className="container-xl bg-light shadow-sm p-3 my-3 border rounded-2">
        <div className="row">
          <div className="col fs-4 fw-bold">Yeni Düğün Sözleşmesi</div>
          <div className="col text-end">
            <Button
              className="shadow-sm fw-bold"
              type="button"
              variant="outline-success"
              as={Link}
              to="/"
            >
              <i className="bi bi-house-door-fill me-1" />
              Anasayfa
            </Button>
          </div>
        </div>
      </div>

      {stageOne && (
        <Fragment>
          <div className="container-xl bg-light shadow-sm p-3 my-3 border rounded-2">
            <div className="row">
              <div className="col fs-2 fw-bold">Müşteri Bilgileri</div>
            </div>
            <Formik
              initialValues={stageOneValues}
              validationSchema={validationSchemaStageOne}
              onSubmit={toggleStageTwo}
              innerRef={formRef}
              validate={validate}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="stageOneContainer container shadow-sm p-3 mt-3 border">
                    <div className="row mt-3 justify-content-center">
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="damatAdi"
                          name="damatAdi"
                          placeholder="Damat Adı"
                        />
                        <label className="form-label ms-3" htmlFor="damatAdi">
                          Damat Adı
                        </label>
                        <ErrorMessage
                          name="damatAdi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          name="damatTelefonu"
                          placeholder="Damat Telefon No"
                          validate={validateDamatTelefonu}
                        >
                          {({ field }) => (
                            <InputMask
                              {...field}
                              mask="0 (999) 999-9999"
                              maskChar=""
                              className="form-control"
                              id="damatTelefonu"
                              name="damatTelefonu"
                              placeholder="0 (   )    -    "
                            />
                          )}
                        </Field>
                        <label
                          className="form-label ms-3"
                          htmlFor="damatTelefonu"
                        >
                          Damat Telefon No
                        </label>
                        <ErrorMessage
                          name="damatTelefonu"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>
                    <div className="row mt-3 justify-content-center">
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="gelinAdi"
                          name="gelinAdi"
                          placeholder="Gelin adı"
                        />
                        <label className="form-label ms-3" htmlFor="gelinAdi">
                          Gelin Adı
                        </label>
                        <ErrorMessage
                          name="gelinAdi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          name="gelinTelefonu"
                          placeholder="Gelin Telefon No"
                          validate={validateGelinTelefonu}
                        >
                          {({ field }) => (
                            <InputMask
                              {...field}
                              mask="0 (999) 999-9999"
                              maskChar=""
                              className="form-control"
                              id="gelinTelefonu"
                              name="gelinTelefonu"
                              placeholder="0 (___) ___-____"
                            />
                          )}
                        </Field>
                        <label
                          className="form-label ms-3"
                          htmlFor="gelinTelefonu"
                        >
                          Gelin Telefon No
                        </label>
                        <ErrorMessage
                          name="gelinTelefonu"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>

                    <div className="row mt-3 justify-content-center">
                      <div className="form-floating col-lg-8 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="adres"
                          name="adres"
                          placeholder="Adres"
                        />
                        <label className="form-label ms-3" htmlFor="adres">
                          Adres
                        </label>
                        <ErrorMessage
                          name="adres"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-lg text-lg-end">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="stageOneDevamBtn btn btn-primary fw-bold"
                        >
                          <i className="bi bi-arrow-right-circle-fill me-1" />
                          Devam et
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Fragment>
      )}

      {stageTwo && (
        <Fragment>
          <div className="container-xl bg-light shadow-sm p-3 my-3 border rounded-2 mb-5 ">
            <div className="row">
              <div className="col fs-2 fw-bold text-center text-md-start">
                Düğün Bilgileri
              </div>
              <div className="col-lg-3">
                <div className="shadow-lg my-2 text-center card text-bg-primary fw-bold">
                  <div className="toplamTutar card-title fs-6 my-2">
                    Toplam Tutar: {toplamTutar}₺
                  </div>
                </div>
              </div>
            </div>
            <Formik
              initialValues={stageTwoValues}
              validationSchema={ValidationSchemaStageTwo}
              onSubmit={handleSubmit}
              innerRef={formRef}
              validate={validate}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <div className="container shadow-sm p-3 mt-3 border bg-white rounded-4">
                    <div className="container-md rounded-4 shadow-sm p-2 border bg-light">
                      <div className="row mt-3">
                        <div className="col offset-md-2 fw-bold fs-4">
                          Düğün Tarihi & Salon Adı
                        </div>
                      </div>

                      <div className="row m-3 mt-2 mb-4 justify-content-center">
                        <div className="form-check col-auto my-auto pe-md-0 me-2 me-md-0">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="idSecenek"
                            name="idSecenek"
                            title="Sözleşme Sıra No"
                          />
                        </div>
                        <div className="form-floating col-lg-2 col-11 my-3 ms-2 ms-md-0">
                          <Field name="id" placeholder="Sıra No">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="id"
                                mask="120999999999999999"
                                maskChar={""}
                                placeholder="500_"
                                disabled={!values.idSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>

                          <label
                            className="form-check-label ms-2 "
                            htmlFor="id"
                          >
                            Sıra No
                          </label>
                          <ErrorMessage
                            name="id"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-4 my-3">
                          <Field
                            type="date"
                            className="form-control"
                            id="dugunTarihi"
                            name="dugunTarihi"
                            placeholder="Düğün Tarihi"
                          />
                          <label
                            className="form-label ms-3"
                            htmlFor="Düğün Tarihi"
                          >
                            Düğün Tarihi
                          </label>
                          <ErrorMessage
                            name="dugunTarihi"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>

                        <div className="col-lg-3 form-floating my-3">
                          <select
                            className="form-select"
                            id="dugunZamani"
                            name="dugunZamani"
                            defaultValue="gunduz"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            {WEDDINGTIME &&
                              WEDDINGTIME.length > 0 &&
                              WEDDINGTIME.map((time, index) => (
                                <option key={index} value={time.value}>
                                  {time.label}
                                </option>
                              ))}
                          </select>
                          <label
                            className="form-label ms-2 fw-bold"
                            htmlFor="dugunZamani"
                            style={{ fontSize: ".95em" }}
                          >
                            Düğün Zamanı
                          </label>

                          <ErrorMessage
                            name="dugunZamani"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>

                        <div className="col-lg-3 form-floating my-3">
                          <select
                            className="form-select"
                            id="dugunSalonu"
                            name="dugunSalonu"
                            onChange={(e) => {
                              setToplamTutar(
                                locations[
                                  locations.findIndex(
                                    (location) =>
                                      location.name === e.target.value
                                  )
                                ].price + Number(additionalPrice)
                              );
                              setPaketTutari(
                                locations[
                                  locations.findIndex(
                                    (location) =>
                                      location.name === e.target.value
                                  )
                                ].price
                              );
                              handleChange(e);
                            }}
                            defaultValue=""
                          >
                            {locations &&
                              locations.length > 0 &&
                              locations.map((location) => (
                                <option key={location.id} value={location.name}>
                                  {location.name}
                                </option>
                              ))}
                          </select>
                          <label
                            className="form-label ms-2 fw-bold"
                            htmlFor="dugunSalonu"
                            style={{ fontSize: ".95em" }}
                          >
                            Düğün Salonu
                          </label>

                          <ErrorMessage
                            name="dugunSalonu"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="container-md rounded-4 shadow-sm p-2 border bg-light mt-4">
                      <div className="row mt-3">
                        <div className="col offset-md-2 fw-bold fs-4">
                          Açıklama & Kişi Sayısı
                        </div>
                      </div>
                      <div className="row m-3 justify-content-center">
                        <div className="form-floating col-lg-5 mb-3">
                          <Field name="aciklama" placeholder="Sıra No">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="aciklama"
                                mask=""
                                maskChar={""}
                                placeholder=""
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>

                          <label
                            className="form-check-label ms-2"
                            htmlFor="aciklama"
                          >
                            Açıklama
                          </label>
                          <ErrorMessage
                            name="aciklama"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>

                        <div className="form-floating col-lg-2 mb-3">
                          <Field name="kisiSayisi" placeholder="Sıra No">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="kisiSayisi"
                                mask=""
                                maskChar={""}
                                placeholder=""
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>

                          <label
                            className="form-check-label ms-2"
                            htmlFor="kisiSayisi"
                          >
                            Kişi Sayısı
                          </label>
                          <ErrorMessage
                            name="kisiSayisi"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                    </div>
                    {/* Foto Albüm Eklenecek / Ödeme silinecek */}

                    <div className="row mt-5 mb-2">
                      <div className="col-lg text-lg-end">
                        <button
                          type="button"
                          className="btn btn-dark fw-bold me-2"
                          onClick={() => {
                            toggleStageOne(values);
                          }}
                        >
                          <i className="bi bi-arrow-left-circle-fill me-1" />
                          Geri Dön
                        </button>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-success shadow fw-bold"
                        >
                          <i className="bi bi-floppy-fill me-1" />
                          Kaydet ve Yazdır
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Fragment>
      )}

      <ToastContainer
        position="top-center"
        autoClose={2500}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition:Flip
      />
    </Fragment>
  );
}
